@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.k-contract
  h1
    font-size: 20px
    margin-bottom: 5px

    h2
      font-size: 16px
      margin-bottom: 5px

      .k-contract-title
        text-transform: uppercase